import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner'; // Import the LoadingSpinner component

export const CsrfContext = createContext();

export const CsrfProvider = ({ children }) => {
  const [csrfToken, setCsrfToken] = useState('');
  const [csrfTokenFetched, setCsrfTokenFetched] = useState(false); // Add state to track if CSRF token is fetched

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get('https://api.bookdentist.pro/api/v1/csrf-token', { withCredentials: true });
        const token = response.data.token;
        setCsrfToken(token);
        //sessionStorage.setItem('csrfToken', token); // Store CSRF token in session storage
        setCsrfTokenFetched(true); // Set state to true when CSRF token is fetched
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    fetchCsrfToken();
  }, []);

  if (!csrfTokenFetched) {
    return <LoadingSpinner />; // Render the LoadingSpinner component until CSRF token is fetched
  }

  return (
    <CsrfContext.Provider value={{ csrfToken, setCsrfToken }}>
      {children}
    </CsrfContext.Provider>
  );
};
