import { useState, useEffect } from 'react';
import API from '../api/axiosConfig';

const useAppointmentTypes = () => {
    const [appointmentTypes, setAppointmentTypes] = useState(() => {
        const storedTypes = sessionStorage.getItem('appointmentTypes');
        return storedTypes ? JSON.parse(storedTypes) : { primary: [], consults: [] };
    });
    const [isLoading, setIsLoading] = useState(() => {
        const storedTypes = sessionStorage.getItem('appointmentTypes');
        return storedTypes ? false : true;
    });
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAppointmentTypes = async () => {
            if (sessionStorage.getItem('appointmentTypes')) {
                setIsLoading(false);
                return;
            }

            setIsLoading(true);
            setError(null);

            try {
                const response = await API.get('/appointments/web/types');
                const typesArray = transformAppointmentData(response.data);
                setAppointmentTypes(typesArray);
                sessionStorage.setItem('appointmentTypes', JSON.stringify(typesArray)); 
            } catch (err) {
                setError(err);
                console.error('Error fetching appointment types:', err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAppointmentTypes();
    }, []);

    return { appointmentTypes, isLoading, error };
};

// Helper function to transform the appointment data
const transformAppointmentData = (data) => {
    const primary = [];
    const consults = [];

    if (Array.isArray(data.primary)) {
        data.primary.forEach(type => {
            primary.push({
                typeName: type.typeName,
                token: type.token
            });
        });
    }

    if (Array.isArray(data.consults)) {
        data.consults.forEach(type => {
            consults.push({
                typeName: type.typeName,
                token: type.token
            });
        });
    }

    return { primary, consults };
};

export default useAppointmentTypes;
