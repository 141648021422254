import React from 'react';
import { Box, Flex, Heading, Image, Spacer, Text } from '@chakra-ui/react';

const Header = ({ branding }) => {
    return (
        <Box
            bg="blue.500"
            width="100%"
            height="15vh"
            display="flex"
            flexDirection="column"
            justifyContent="left"
            boxShadow="md"
        >
            <Flex
                justify="left"
                align="left"
                flexDirection="column"
                maxW="100%"
                pl={4}
                height="100%"
            >
                <Flex justify="start" align="left" width="100%" pt={4}>
                    <Image
                        src={branding.logoUrl}
                        alt="Logo"
                        maxH="4vh"
                        mr={4}
                    />
                </Flex>
                <Spacer />
                <Box textAlign="left" width="100%" mb={5}>
                    <Heading as="h1" size="lg" color="white">
                        {branding.headerText}
                    </Heading>
                    <Text fontSize="md" color="white">
                        {branding.subHeaderText}
                    </Text>
                </Box>
            </Flex>
        </Box>
    );
};

export default Header;
