import { useState, useEffect } from 'react';
import API from '../api/axiosConfig';

const useAppointments = () => {
    const [appointments, setAppointments] = useState({ past: [], upcoming: [] });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    const fetchAppointments = async () => {
        try {
            const response = await API.get('/appointments/web/mine');
            setAppointments(response.data);
            setIsLoading(false);
        } catch (err) {
            setError('Failed to fetch appointments.');
            setIsLoading(false);
            console.error('Error fetching appointments:', err);
        }
    };

    useEffect(() => {
        fetchAppointments();
    }, []);

    return { appointments, isLoading, error, fetchAppointments };
};

export default useAppointments;