import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text, Spinner, HStack } from '@chakra-ui/react';
import '../styles/components/ConfirmModal.css'; // Import the external CSS file

const ConfirmModal = ({ isOpen, onClose, onConfirm, appointment, actionType, isLoading }) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="modal-content">
            <ModalHeader>Confirm {actionType === 'cancel' ? 'Cancellation' : 'Reschedule'}</ModalHeader>
            <ModalCloseButton className="close-button" />
            <ModalBody>
                <Text className="modal-body-text">Are you sure you want to {actionType} this appointment?</Text>
                <Text className="modal-body-text"><strong>Title:</strong> {appointment.AppointmentTitle}</Text>
                <Text className="modal-body-text"><strong>Date:</strong> {new Date(appointment.AptDateTime).toLocaleDateString()}</Text>
                <Text className="modal-body-text"><strong>Time:</strong> {new Date(appointment.AptDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Text>
                {isLoading && (
                    <HStack className="loading-container">
                        <Spinner />
                        <Text>{actionType === 'cancel' ? 'Cancelling appointment...' : 'Rescheduling appointment...'}</Text>
                    </HStack>
                )}
            </ModalBody>
            <ModalFooter className="modal-footer-buttons">
                <HStack spacing={2}>
                    <Button colorScheme="red" onClick={onConfirm} isDisabled={isLoading}>
                        Yes
                    </Button>
                    <Button onClick={onClose} isDisabled={isLoading}>
                        No
                    </Button>
                </HStack>
            </ModalFooter>
        </ModalContent>
    </Modal>
);

export default ConfirmModal;
