import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, VStack, HStack, Icon, Button, Divider, useDisclosure, Center, Spinner } from '@chakra-ui/react';
import { FaCalendarAlt } from 'react-icons/fa';
import ConfirmModal from './ConfirmModal';
import API from '../api/axiosConfig';
import { useNavigate } from 'react-router-dom';
import '../styles/components/AppointmentsList.css'; // Import the external CSS file

const AppointmentsList = ({ appointments, title, onAppointmentChange, isPastAppointment }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [actionType, setActionType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingAppointments, setLoadingAppointments] = useState(false);
    const [fetchAppointmentsOnClose, setFetchAppointmentsOnClose] = useState(false);
    const [cancelThresholdHours, setCancelThresholdHours] = useState(null);
    const [rescheduleThresholdHours, setRescheduleThresholdHours] = useState(null);
    const [loadingThresholds, setLoadingThresholds] = useState(true);
    const navigate = useNavigate();

    const handleOpenModal = (appointment, type) => {
        setSelectedAppointment(appointment);
        setActionType(type);
        onOpen();
    };

    const handleConfirm = async () => {
        if (actionType === 'reschedule') {
            sessionStorage.setItem('selectedType', selectedAppointment.AptData); // Storing encrypted data
            navigate('/book-appointment', {
                state: {
                    selectedAppointment: selectedAppointment,
                },
            });
        } else {
            await changeAppointment();
        }
    };

    const changeAppointment = async () => {
        setIsLoading(true);
        try {
            const response = await API.post('/appointments/web/change', {
                cancel: selectedAppointment.AptData, // Passing encrypted data
            });
            if (response.data.status === 'success') {
                setFetchAppointmentsOnClose(true); // Set flag to fetch appointments on modal close
                onClose(); // Close the modal after successful cancellation
            }
        } catch (error) {
            console.error('Error changing appointment:', error);
            onClose();
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchThresholds = async () => {
            try {
                const response = await API.get('/appointments/web/thresholds'); // Replace with your actual API endpoint
                if (response.data) {
                    setCancelThresholdHours(response.data.cancelThresholdHours);
                    setRescheduleThresholdHours(response.data.rescheduleThresholdHours);
                } else {
                    console.error('Failed to fetch threshold values');
                }
            } catch (error) {
                console.error('Error fetching threshold values:', error);
            } finally {
                setLoadingThresholds(false);
            }
        };

        fetchThresholds();
    }, []);

    useEffect(() => {
        const fetchUpdatedAppointments = async () => {
            setLoadingAppointments(true);
            await onAppointmentChange(); // Fetch the updated appointments list
            setLoadingAppointments(false);
        };

        if (!isOpen && fetchAppointmentsOnClose) {
            fetchUpdatedAppointments();
            setFetchAppointmentsOnClose(false); // Reset the flag
        }

        if (!isOpen && !fetchAppointmentsOnClose) {
            setSelectedAppointment(null); // Clear the selected appointment only when not fetching
        }
    }, [isOpen, fetchAppointmentsOnClose, onAppointmentChange]);

    const isWithinThreshold = (appointmentDateTime, thresholdHours) => {
        const now = new Date();
        const appointmentTime = new Date(appointmentDateTime);
        const timeDifference = (appointmentTime - now) / (1000 * 60 * 60); // Convert time difference to hours
        return timeDifference <= thresholdHours;
    };

    return (
        <Box>
            <Heading size="md" mb={4}>{title}</Heading>
            {loadingThresholds ? (
                <Center mt={4} className="loading-center">
                    <Spinner size="xl" />
                    <Text ml={4}>Loading thresholds...</Text>
                </Center>
            ) : loadingAppointments ? (
                <Center mt={4} className="loading-center">
                    <Spinner size="xl" />
                    <Text ml={4}>Loading appointments...</Text>
                </Center>
            ) : (
                <VStack align="stretch" spacing={4}>
                    {appointments.length ? appointments.map((appointment, index) => {
                        const isPast = new Date(appointment.AptDateTime) < new Date();
                        const disableCancel = cancelThresholdHours !== null && isWithinThreshold(appointment.AptDateTime, cancelThresholdHours);
                        const disableReschedule = rescheduleThresholdHours !== null && isWithinThreshold(appointment.AptDateTime, rescheduleThresholdHours);

                        return (
                            <Box
                                key={index}
                                className={`appointment-card ${isPast ? 'past' : 'upcoming'}`}
                            >
                                <HStack mb={2} alignItems="center">
                                    <Icon as={FaCalendarAlt} className="icon" />
                                    <Text fontSize="lg" fontWeight="bold">{appointment.AppointmentTitle}</Text>
                                </HStack>
                                <Divider mb={2} />
                                <Text><strong>Date:</strong> {new Date(appointment.AptDateTime).toLocaleDateString()}</Text>
                                <Text><strong>Time:</strong> {new Date(appointment.AptDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Text>
                                <Text><strong>Status:</strong> {appointment.AptStatus}</Text>
                                <HStack className="button-group" spacing={2}>
                                    {isPast ? (
                                        <Text color="gray.500">Past Appointment</Text>
                                    ) : (
                                        <>
                                            <Button 
                                                colorScheme="red" 
                                                size="sm" 
                                                onClick={() => handleOpenModal(appointment, 'cancel')} 
                                                isDisabled={disableCancel}
                                            >
                                                Cancel
                                            </Button>
                                            <Button 
                                                colorScheme="blue" 
                                                size="sm" 
                                                onClick={() => handleOpenModal(appointment, 'reschedule')} 
                                                isDisabled={disableReschedule}
                                            >
                                                Reschedule
                                            </Button>
                                        </>
                                    )}
                                </HStack>
                            </Box>
                        );
                    }) : <Text>No {title.toLowerCase()}.</Text>}
                </VStack>
            )}
            {selectedAppointment && (
                <ConfirmModal
                    isOpen={isOpen}
                    onClose={() => {
                        setFetchAppointmentsOnClose(false); // Ensure no fetch occurs on close
                        onClose();
                    }}
                    onConfirm={handleConfirm}
                    appointment={selectedAppointment}
                    actionType={actionType}
                    isLoading={isLoading}
                />
            )}
        </Box>
    );
};

export default AppointmentsList;
