import React, { useState, useEffect, useContext } from 'react';
import { Box, Text, VStack, Heading, Center, HStack, IconButton, useToast } from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import useAppointmentTypes from '../hooks/useAppointmentTypes';
import useBookAppointment from '../hooks/useBookAppointment';
import AppointmentsGroup from '../components/AppointmentsGroup';
import AppointmentSlots from '../components/AppointmentSlots';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorAlert from '../components/ErrorAlert';
import API from '../api/axiosConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import { WebAppContext } from '../contexts/WebAppContext';
import "../styles/pages/BookAppointment.css"; // Import the CSS file

const BookAppointment = () => {
    const { appointmentTypes, isLoading, error } = useAppointmentTypes();
    const [selectedType, setSelectedType] = useState(() => {
        const savedType = sessionStorage.getItem('selectedType');
        return savedType ? savedType : null;
    });
    const [slots, setSlots] = useState([]);
    const [loadingSlots, setLoadingSlots] = useState(false);
    const [slotsError, setSlotsError] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 13)));
    const { patientType } = useContext(WebAppContext);

    const { bookAppointment, isBooking, bookingError } = useBookAppointment();
    const navigate = useNavigate();
    const toast = useToast();
    const location = useLocation();
    const selectedAppointment = location.state?.selectedAppointment;
    const isReschedule = Boolean(selectedAppointment); // Ensure isReschedule is true if selectedAppointment is present

    useEffect(() => {
        const savedType = sessionStorage.getItem('selectedType');
        const savedSlots = sessionStorage.getItem('slots');
        
        if (selectedAppointment) {
            setSelectedType(savedType ? savedType : null);
            setSlots([]);
            setLoadingSlots(true);
            if (savedType) {
                fetchSlots(savedType, startDate.toISOString().split('T')[0]);
            }
        } else {
            if (savedType) {
                setSelectedType(savedType);
                setSlots(savedSlots ? JSON.parse(savedSlots) : []);
                setLoadingSlots(true);
                fetchSlots(savedType, startDate.toISOString().split('T')[0]);
            } 
            if (savedSlots) {
                setSlots(savedSlots);
            }
        }
    }, [location, selectedAppointment, startDate]);

    const fetchSlots = async (type, dateStart) => {
        const numberOfDays = 14;
        try {
            const response = await API.get(`/appointments/web/open/slots`, {
                params: {
                    datestart: dateStart,
                    numberofdays: numberOfDays,
                    _at: type
                }
            });
            setSlots(response.data);
            sessionStorage.setItem('slots', JSON.stringify(response.data));
        } catch (err) {
            setSlotsError('Failed to load slots. Please try again.');
            sessionStorage.removeItem('slots');
        } finally {
            setLoadingSlots(false);
        }
    };

    const handleSelectType = ({ token }) => {
        setSelectedType(token);
        sessionStorage.setItem('selectedType', token);
        setSlots([]);
        setLoadingSlots(true);
        setSlotsError(null);
        const dateStart = startDate.toISOString().split('T')[0];
        fetchSlots(token, dateStart);
    };

    const handlePrevDates = () => {
        setLoadingSlots(true);
        setSlots([]);
        const newStartDate = new Date(startDate);
        newStartDate.setDate(newStartDate.getDate() - 14);
        const newEndDate = new Date(endDate);
        newEndDate.setDate(newEndDate.getDate() - 14);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        fetchSlots(selectedType, newStartDate.toISOString().split('T')[0]);
    };

    const handleNextDates = () => {
        setLoadingSlots(true);
        setSlots([]);
        const newStartDate = new Date(startDate);
        newStartDate.setDate(newStartDate.getDate() + 14);
        const newEndDate = new Date(endDate);
        newEndDate.setDate(newEndDate.getDate() + 14);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        fetchSlots(selectedType, newStartDate.toISOString().split('T')[0]);
    };

    const handleConfirmBooking = async (_pos, date, time) => {
        const appointmentType = selectedType;
        const isNewPatient = patientType === 'new';
        let rescheduleSuccess = false;
        try {
            const bookingSuccess = await bookAppointment(_pos, date, time, appointmentType, isNewPatient, isReschedule);
            if (bookingSuccess) {
                if (selectedAppointment) {
                    try {
                        const response = await API.post('/appointments/web/change', {
                            reschedule: selectedAppointment.AptData
                        });
    
                        rescheduleSuccess = response.data.status === 'success';
    
                        toast({
                            title: rescheduleSuccess ? "Rescheduling Successful." : "Appointment Rescheduled",
                            description: rescheduleSuccess ? "Your appointment has been rescheduled." : "Your appointment has been rescheduled. But we could not remove your old appointment.",
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                        });
                    } catch (error) {
                        toast({
                            title: "New Appointment booked",
                            description: "Your appointment has been booked. But we could not remove your old appointment.",
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                        });
                    }
                } else {
                    toast({
                        title: "Booking successful",
                        description: "Your appointment has been booked.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                }
                navigate('/thank-you', { state: { bookingResponse: bookingSuccess, rescheduleSuccess } });
            } else {
                toast({
                    title: "Booking failed",
                    description: "There was an error booking your appointment. Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            let description = "There was an error booking your appointment. Please try again.";
            if (error.response?.data?.error) {
                description = error.response.data.error;
            }
            toast({
                title: "Booking failed",
                description: description,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };       

    const formattedStartDate = startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    const formattedEndDate = endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

    if (isLoading) return <LoadingSpinner />;
    if (error) return <ErrorAlert title="Error" description="Failed to load appointment types. Please try again." onClose={() => {}} />;

    /*const consults = appointmentTypes.Consults ? appointmentTypes.Consults.map(type => Object.keys(type)[0]) : [];
    const otherTypes = Object.keys(appointmentTypes).filter(key => key !== 'Consults');*/

    return (
        <Box className="book-appointment-container">
            {slotsError && <ErrorAlert title="Error" description={slotsError} onClose={() => setSlotsError(null)} />}
            {!selectedType && (
                <Center className="select-type-heading">
                    <Heading as="h3" size="lg" fontWeight="bold">What type of care are you looking for?</Heading>
                </Center>
            )}
            {!selectedType ? (
                <VStack className="select-type-list">
                    {appointmentTypes.primary && appointmentTypes.primary.map(({ typeName, token }, index) => (
                        <Box
                            key={index}
                            className="type-box"
                            onClick={() => handleSelectType({ typeName, token })}
                        >
                            <Text className="type-text">{typeName}</Text>
                        </Box>
                    ))}
                    {appointmentTypes.consults && appointmentTypes.consults.length > 0 && (
                        <Box className="consult-box">
                            <Text className="type-text">Consults</Text>
                            <VStack className="consult-list">
                                {appointmentTypes.consults.map(({ typeName, token }, index) => (
                                    <Box
                                        key={index}
                                        className="type-box"
                                        onClick={() => handleSelectType({ typeName, token })}
                                    >
                                        <Text className="type-text">{typeName}</Text>
                                    </Box>
                                ))}
                            </VStack>
                        </Box>
                    )}
                </VStack>
            ) : (
                <Box>
                    <AppointmentsGroup
                        title="Appointment Types"
                        appointments={[...appointmentTypes.primary, ...appointmentTypes.consults]}
                        onSelect={handleSelectType}
                        selectedType={selectedType}
                        isClickable={!selectedAppointment}
                    />
                    <HStack className="slots-header">
                        <Text>{slots.length ? `${slots.length} providers` : 'Loading providers...'}</Text>
                        <HStack>
                            <IconButton
                                icon={<FaChevronLeft />}
                                onClick={handlePrevDates}
                                isDisabled={startDate.toDateString() === new Date().toDateString()}
                                aria-label="Previous dates"
                            />
                            <Text>{`${formattedStartDate} – ${formattedEndDate}`}</Text>
                            <IconButton
                                icon={<FaChevronRight />}
                                onClick={handleNextDates}
                                aria-label="Next dates"
                            />
                        </HStack>
                    </HStack>
                    {loadingSlots ? <LoadingSpinner message="Loading slots..." /> : <AppointmentSlots slots={slots} error={slotsError} startDate={startDate} onConfirmBooking={handleConfirmBooking} isBooking={isBooking} bookingError={bookingError} selectedAppointment={selectedAppointment} />}
                </Box>
            )}
        </Box>

    );
};

export default BookAppointment;
