import React, { useEffect, useRef } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import "../styles/components/AppointmentsGroup.css"; // Import the CSS file

const AppointmentsGroup = ({ title, appointments = [], onSelect, selectedType, isClickable = true }) => {
    const selectedRef = useRef(null);
    useEffect(() => {
        if (selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
    }, [selectedType]);

    return (
        <Box className={`appointments-group-container ${!isClickable ? 'disabled' : ''}`}>
            <Text className="appointments-group-title">{title}</Text>
            <HStack className="appointments-group-list">
                {appointments.map((appointment, index) => (
                    <Box
                        key={index}
                        className={`type-box ${selectedType && selectedType === appointment.token ? 'selected' : ''}`}
                        onClick={() => isClickable && onSelect(appointment)}
                        ref={selectedType && selectedType === appointment.token ? selectedRef : null}
                    >
                        <Text className="type-text">{appointment.typeName}</Text>
                    </Box>
                ))}
            </HStack>
        </Box>
    );
};

export default AppointmentsGroup;
