// /src/components/NavigationHeader.js
import React from 'react';
import { Box, Flex, Button, Spacer, Heading, Avatar, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';

const NavigationHeader = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    const uuid = sessionStorage.getItem('UUID');

    // Clear session storage
    sessionStorage.clear();

    // Clear the authToken cookie by setting its expiry date to a past date
    document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Set the UUID back to session storage
    if (uuid) {
        sessionStorage.setItem('UUID', uuid);
    }

    // Redirect to the login page
    window.location.href = `/${uuid}`;
  };

  return (
    <Box bg="blue.500" px={4}>
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <Heading as="h1" size="md" color="white">
          
        </Heading>

        <Spacer />

        <Flex alignItems="center">
          <Menu>
            <MenuButton
              as={Button}
              rounded="full"
              variant="link"
              cursor="pointer"
              minW={0}
            >
              <Avatar size="sm"/>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleLogout} icon={<FaSignOutAlt />}>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavigationHeader;
