import React, { useState } from 'react';
import { Box, Text, SimpleGrid, VStack, HStack, useBreakpointValue } from '@chakra-ui/react';
import DayCard from './DayCard';
import ErrorAlert from './ErrorAlert';
import AppointmentBookingModal from './AppointmentBookingModal';
import '../styles/components/AppointmentSlots.css'; // Import the external CSS file

const AppointmentSlots = ({ slots, error, startDate, onConfirmBooking, isBooking, bookingError, selectedAppointment }) => {
    const [selectedDayInfo, setSelectedDayInfo] = useState(null);

    const handleDayClick = (_pos, provider, appointment) => {
        setSelectedDayInfo({ _pos, provider, appointment });
    };

    const isMobile = useBreakpointValue({ base: true, md: false });
    const gridColumns = useBreakpointValue({ base: 2, sm: 3, md: 5, lg: 7 });

    if (error) {
        return <ErrorAlert title="Error" description={error} onClose={() => {}} />;
    }

    if (!slots.length) {
        return <Text>No slots available for the selected appointment type.</Text>;
    }

    // Generate an array of dates for the next 14 days starting from the provided startDate
    const dates = Array.from({ length: 14 }, (_, i) => {
        const date = new Date(startDate);
        date.setDate(date.getDate() + i);
        return date.toDateString();
    });

    return (
        <VStack className="appointment-slots-container">
            {slots.map((slot, index) => (
                <Box key={index} className="slot-box">
                    <HStack className="slot-content">
                        <Box className="provider-info">
                            <Text className="provider-name">{slot.provider.FullName || slot.provider.Abbr}</Text>
                            <Text className="provider-specialty">{slot.provider.SpecialtyName}</Text>
                        </Box>
                        <Box className="appointments-grid">
                            {isMobile ? (
                                <HStack className="mobile-scroll">
                                    {dates.map((date, i) => {
                                        const appointment = slot.appointments.find(a => new Date(a.date).toDateString() === date);
                                        const count = appointment ? appointment.time.length : 0;
                                        return (
                                            <Box key={i} className="day-card-container">
                                                <DayCard
                                                    date={new Date(date).toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}
                                                    count={count}
                                                    isDisabled={count === 0}
                                                    onClick={() => !count ? null : handleDayClick(slot._pos, slot.provider, appointment)}
                                                />
                                            </Box>
                                        );
                                    })}
                                </HStack>
                            ) : (
                                <SimpleGrid columns={gridColumns} spacing={4}>
                                    {dates.map((date, i) => {
                                        const appointment = slot.appointments.find(a => new Date(a.date).toDateString() === date);
                                        const count = appointment ? appointment.time.length : 0;
                                        return (
                                            <Box key={i} className="day-card-container">
                                                <DayCard
                                                    date={new Date(date).toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}
                                                    count={count}
                                                    isDisabled={count === 0}
                                                    onClick={() => !count ? null : handleDayClick(slot._pos, slot.provider, appointment)}
                                                />
                                            </Box>
                                        );
                                    })}
                                </SimpleGrid>
                            )}
                        </Box>
                    </HStack>
                </Box>
            ))}
            {selectedDayInfo && (
                <AppointmentBookingModal
                    isOpen={!!selectedDayInfo}
                    onClose={() => setSelectedDayInfo(null)}
                    _pos={selectedDayInfo._pos}
                    provider={selectedDayInfo.provider}
                    appointment={selectedDayInfo.appointment}
                    onConfirmBooking={onConfirmBooking}
                    isBooking={isBooking}
                    bookingError={bookingError}
                    selectedAppointment={selectedAppointment}
                />
            )}
        </VStack>
    );
};

export default AppointmentSlots;
