// /src/utils/extractUUID.js

export const extractUUID = (url) => {
    const pathSegments = url.split('/');
    // Extract the last path segment, which might contain query parameters
    const lastSegment = pathSegments[pathSegments.length - 1];
    // Remove any query parameters if present
    const uuid = lastSegment.split('?')[0];
    return uuid;
  };
  