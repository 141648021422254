import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Box, VStack, Text, useToast } from '@chakra-ui/react';
import API from '../api/axiosConfig';
import ErrorAlert from '../components/ErrorAlert';
import "../styles/pages/VerifyOTP.css"; // Import the CSS file

const VerifyOTP = () => {
    const [otp, setOtp] = useState('');
    const [token, setToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        const otpToken = sessionStorage.getItem('otpToken');
        if (otpToken) {
            setToken(otpToken);
        } else {
            toast({
                title: "Session Error",
                description: "Session expired. Please try logging in again.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            navigate('/login');
        }
    }, [navigate, toast]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (value.length <= 4) {
            setOtp(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (otp.length !== 4) {
            toast({
                title: "Validation Error",
                description: "OTP must be 4 digits.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        setIsLoading(true);
        setError('');
        try {
            const response = await API.post('/web/otp/verify', { otp, token }, {
                headers: { 'Authorization': `Bearer ${sessionStorage.getItem('tempToken')}` }
            });
            if (response.data.message === 'Authentication successful') {
                toast({
                    title: "Success",
                    description: "Authentication successful!",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                sessionStorage.removeItem('tempToken');
                sessionStorage.removeItem('appointmentTypes');
                sessionStorage.removeItem('selectedType');
                sessionStorage.removeItem('slots');
                sessionStorage.removeItem('otpToken');
                navigate('/dashboard');
            } else {
                throw new Error(response.data.message || "Failed to verify OTP.");
            }
        } catch (error) {
            setError(error.message || "An error occurred while verifying the OTP.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <VStack className="verify-otp-container" spacing={4} align="center" justify="center">
            {error && <ErrorAlert title="Verification Error" description={error} onClose={() => setError('')} />}
            <Box className="otp-box">
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                        <Text className="otp-text">Enter your OTP:</Text>
                        <Input
                            type="text"
                            value={otp}
                            onChange={handleInputChange}
                            placeholder="Enter 4-Digits"
                            isRequired
                            focusBorderColor="blue.500"
                            isDisabled={isLoading}
                            className="otp-input"
                            maxLength={4}
                            textAlign="center"
                        />
                        <Button
                            type="submit"
                            colorScheme="blue"
                            isLoading={isLoading}
                            loadingText="Verifying"
                            className="otp-button"
                        >
                            Verify OTP
                        </Button>
                    </VStack>
                </form>
            </Box>
        </VStack>
    );
};

export default VerifyOTP;
