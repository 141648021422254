import React, { useState, useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes/Routes';
import { WebAppProvider } from './contexts/WebAppContext';
import { CsrfProvider, CsrfContext } from './contexts/CsrfSyncContext'; // Import CsrfProvider and CsrfContext
import { setCsrfToken } from './api/axiosConfig'; // Import Axios instance and token setter

const App = () => {
  const [tempToken, setTempToken] = useState(sessionStorage.getItem('tempToken') || '');

  useEffect(() => {
    const handleStorageChange = () => {
      setTempToken(sessionStorage.getItem('tempToken') || '');
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <ChakraProvider>
      <Router>
        <CsrfProvider>
          <CsrfContext.Consumer>
            {({ csrfToken }) => {
              // Set CSRF token for Axios
              setCsrfToken(csrfToken);
              return (
                <WebAppProvider>
                  <Routes tempToken={tempToken} />
                </WebAppProvider>
              );
            }}
          </CsrfContext.Consumer>
        </CsrfProvider>
      </Router>
    </ChakraProvider>
  );
};

export default App;
