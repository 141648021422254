// /src/contexts/WebAppContext.js

import React, { createContext, useState } from 'react';

export const WebAppContext = createContext();

export const WebAppProvider = ({ children }) => {
  const [webAppInfo, setWebAppInfo] = useState({});
  const [patientType, setPatientType] = useState(''); // Add patientType state

  return (
    <WebAppContext.Provider value={{ webAppInfo, setWebAppInfo, patientType, setPatientType }}>
      {children}
    </WebAppContext.Provider>
  );
};
