import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import LoadingSpinner from './LoadingSpinner';
import NavigationHeader from './NavigationHeader'; // Import the NavigationHeader component

const PrivateRoute = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth();
    const uuid = sessionStorage.getItem('UUID');

    if (isLoading) return <LoadingSpinner />;

    if (!isAuthenticated) {
        return <Navigate to={`/${uuid}`} replace />;
    }

    return (
        <>
            <NavigationHeader />
            {children}
        </>
    );
};

export default PrivateRoute;
