import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import '../styles/components/DayCard.css'; // Import the external CSS file

const DayCard = ({ date, count, isDisabled, onClick }) => {
    return (
        <Box
            className={`day-card ${isDisabled ? 'disabled' : ''}`}
            onClick={onClick}
        >
            <Text className="day-text">{date}</Text>
            <Text className="day-text">
                {isDisabled ? 'No appt available' : `${count} ${count === 1 ? 'appt' : 'appts'}`}
            </Text>
        </Box>
    );
};

export default DayCard;
