import { useState } from 'react';
import API from '../api/axiosConfig';

const useBookAppointment = () => {
    const [bookingError, setBookingError] = useState(null);
    const [isBooking, setIsBooking] = useState(false);

    const bookAppointment = async (_pos, date, time, _at, isNewPatient, isReschedule) => {
        setIsBooking(true);
        setBookingError(null);

        const appointmentDateTime = `${date} ${time}`;
        try {
            const response = await API.post('/appointments/web/book', {
                aptdatetime: appointmentDateTime,
                isnewpatient: isNewPatient ? "true" : "false",
                isReschedule: isReschedule,
                _pos: _pos,
                _at: _at
            });
            return response.data;
        } catch (error) {
            let description = "There was an error booking your appointment. Please try again.";
            if (error.response?.data?.error) {
                description = error.response.data.error;
            }
            setBookingError(description);
            throw error; 
        } finally {
            setIsBooking(false);
        }
    };

    return { bookAppointment, isBooking, bookingError };
};

export default useBookAppointment;
