import React from 'react';
import { Box, Button, Input, Text, Heading, VStack, HStack, Image, Link, SimpleGrid } from '@chakra-ui/react';
import deltadental from '../images/deltadental.svg';  // Path to the SVG
import aetna from '../images/aetna.svg';            // Path to the SVG
import guardian from '../images/guardian.svg';      // Path to the SVG
import metlife from '../images/metlife.svg';        // Path to the SVG
import '../styles/pages/HomePage.css'; // Import the external CSS file

// HomePage Component
const HomePage = () => {
    return (
        <VStack spacing={4} align="center" className="home-page-container">
            <MarketingSection />
            <SearchSection />
            <InsuranceLinks />
        </VStack>
    );
};

// Marketing Section with illustrations and call to action
const MarketingSection = () => (
    <VStack spacing={4} align="center" className="marketing-section">
        <Heading as="h1" size="xl" className="marketing-heading">Book 100s+ of the best Dentists near me</Heading>
        <Text className="marketing-text">
            Need to make a dentist appointment this week?
        </Text>
        <Text className="marketing-text">
            Find dentists near you who take your insurance. It’s simple, secure, and free.
        </Text>
    </VStack>
);

// Search Section for finding dentists
const SearchSection = () => (
    <HStack as="form" spacing={4} className="search-section">
        <Input placeholder="Dentist" />
        <Input placeholder="Location" defaultValue="New York, NY" />
        <Input placeholder="Insurance carrier and plan" />
        <Button colorScheme="blue" className="search-button">Search</Button>
    </HStack>
);

// Insurance Links Section for quick access to insurance information
const InsuranceLinks = () => (
    <VStack spacing={3} align="stretch" className="insurance-links">
        <Heading as="h3" size="md" className="insurance-heading">Find an in-network dentist from over 1,000 insurance plans</Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={2}>
            <InsuranceLink id="223" imgSrc={deltadental} />
            <InsuranceLink id="222" imgSrc={aetna} />
            <InsuranceLink id="212" imgSrc={guardian} />
            <InsuranceLink id="239" imgSrc={metlife} />
            <Link href="#" className="see-all-link">See all (1,000+)</Link>
        </SimpleGrid>
        <Button className="insurance-check-button">Free Insurance Coverage Check</Button>
    </VStack>
);

// Reusable component for rendering insurance links with SVG on cards
const InsuranceLink = ({ id, imgSrc }) => (
    <Box as={Link} href={`#insurance-${id}`} className="insurance-link-box">
        <Image src={imgSrc} alt="Insurance logo" className="insurance-logo" />
    </Box>
);

export default HomePage;
